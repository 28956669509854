<template>
  <main class="home">
    <div class="container">
      <img src="@/assets/guk.png" alt="Gaymers UK Logo"/>
      <h1>Gaymers UK</h1>
      <p class="lead">Gaymers UK is a welcoming community with a diverse and inclusive membership. We run an SFW, 18+ server with a focus on chat and socialising and to be a safe space to play games with others.</p>
      <a class="button" :class="{ outline: prefersDark }" href="https://discord.com/invite/u8uvm7Q">
        Join our Discord server
      </a>
      <p>We operate our own Minecraft (Java) server and also have many regular players of League of Legends, Dead by Daylight and the Civilization series, among others.</p>
      <p>We hold regular <a href="/trivia">quiz nights</a> (at least once a month) and aim to hold more server events in future.</p>
      <p>Although we are a UK based server, membership is open to all gamers, so feel free to pop in and we'll make you a lovely cup of tea!</p>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      prefersDark: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    }
  },
  mounted () {
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        this.prefersDark = e.matches
      })
  }
}
</script>

<style lang="scss">
.home {
  display: flex;

  .container {
    margin: 0 auto;
    text-align: center;
    padding: 1rem;

    @include breakpoint(md) {
      max-width: 75vw;
    }

    @include breakpoint(lg) {
      max-width: 50vw;
    }

    @include breakpoint(xl) {
      max-width: 40vw;
    }
  }

  img {
    margin-top: calc(var(--rad) * 2);
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 10px solid var(--c-bg);

    --rad: 30px;

    @include breakpoint(md) {
      --rad: 50px;
      width: 200px;
      height: 200px;
    }

    @include breakpoint(lg) {
      --rad: 60px;
      width: 250px;
      height: 250px;
    }

    @include breakpoint(xl) {
      --rad: 70px;
      width: 300px;
      height: 300px;
    }

    box-shadow:
      0 0 0 calc(var(--rad) * 1) rgba($red, 0.1),
      0 0 0 calc(var(--rad) * 1) var(--c-bg),
      0 0 0 calc(var(--rad) * 2) rgba($orange, 0.1),
      0 0 0 calc(var(--rad) * 2) var(--c-bg),
      0 0 0 calc(var(--rad) * 3) rgba($yellow, 0.1),
      0 0 0 calc(var(--rad) * 3) var(--c-bg),
      0 0 0 calc(var(--rad) * 4) rgba($green, 0.1),
      0 0 0 calc(var(--rad) * 4) var(--c-bg),
      0 0 0 calc(var(--rad) * 5) rgba($blue, 0.1),
      0 0 0 calc(var(--rad) * 5) var(--c-bg),
      0 0 0 calc(var(--rad) * 6) rgba($purple, 0.1);
  }

  h1 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  p + p {
    margin-top: 1.5rem;
  }

  p.lead {
    font-weight: 300;
    font-size: 20px;
  }

  .button {
    margin: 2rem 0;
  }
}
</style>
