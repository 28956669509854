<template>
  <nav id="nav">
    <router-link to="/" tag="h1" class="brand">Gaymers UK</router-link>
    <ul class="links" :class="{expanded}">
      <router-link
        v-for="item in items"
        :key="item.name"
        :to="item.to"
        tag="li"
      >
        {{item.name}}
      </router-link>
    </ul>
    <button @click="toggle" class="toggle inverted outline icon" v-if="items.length > 0">
      <font-awesome-icon icon="bars" v-if="!expanded" />
      <font-awesome-icon icon="chevron-up" v-if="expanded" />
    </button>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      items: [
        { name: 'Trivia', to: '/trivia' }
      ],
      expanded: false
    }
  },
  methods: {
    toggle () {
      this.expanded = !this.expanded
    }
  },
  watch: {
    $route () {
      this.expanded = false
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  min-height: $navbar-height;
  display: flex;
  flex-direction: column;
  position: relative;

  @include breakpoint(md) {
    height: $navbar-height;
    flex-direction: row;
  }

  background-color: var(--c-primary);
  color: var(--c-text-on-primary);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  line-height: $navbar-height;

  .toggle {
    height: 2rem;
    margin-top: 0.5rem;
    position: absolute;
    right: 0.5rem;

    @include breakpoint(md) {
      display: none;
    }
  }

  .brand {
    padding: 0;
    margin: 0 1rem;
    color: var(--c-text-on-primary);
    font-weight: 600;
    cursor: pointer;
  }

  .links {

    display: none;
    flex-direction: column;
    background-color: var(--c-bg-shade);

    &.expanded {
      display: flex;
    }

    @include breakpoint(md) {
      display: block;
      flex-direction: row;
      background-color: var(--c-primary);
    }

    li {
      display: inline-block;
      height: 100%;
      padding: 0 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 200ms, border-width 200ms;

      &.router-link-exact-active {
        background-color: var(--c-bg);
        text-decoration: underline;

        @include breakpoint(md) {
          background-color: transparent;
          text-decoration: none;
          border-bottom: 4px solid var(--c-text-on-primary);
        }
      }

      &:hover {
        background: var(--c-primary-shade);
      }
    }
  }
}
</style>
