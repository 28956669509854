<template>
  <div id="app">
    <Navbar/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from './components/Navbar'

export default {
  name: 'App',
  components: { Navbar }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap');
@import "@/style/_theme.scss";
@import "@/style/_controls.scss";

html, body, #app {
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--c-bg);
  color: var(--c-text-on-bg);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content-wrapper {
  display: flex;
}

.content {
  padding: 1rem;

  &.flex {
    margin: 0 auto;
    width: 100vw;
    max-width: 100%;

    @include breakpoint(md) {
      width: 90vw;
    }

    @include breakpoint(lg) {
      width: 70vw;
    }

    @include breakpoint(xl) {
      width: 60vw;
    }
  }

  &.center {
    text-align: center;
  }

  p {
    &.lead {
      font-size: 1.2em;
      font-weight: 300;
    }

    p + & {
      margin-top: 1em;
    }
  }
}
</style>
